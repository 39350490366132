<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Fundamental Skills 2: Plan for Original Investigation</h2>

      <p class="mb-n3">
        a) How do you plan to conclusively determine the identity of the component(s) in the unknown
        substance/mixture?
      </p>

      <v-radio-group
        v-model="inputs.multipleChoiceAnswer"
        class="ml-6 mb-1"
        :disabled="!allowEditing"
      >
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        b) What method will you use in the original investigation? Provide 3 to 6 bulleted
        procedural steps describing how you will use glassware, equipment, and instrumentation.
        Additionally, detail the data acquired during fundamental skills and propose the
        variables/data you will need to collect.
      </p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'UBC154KineticsPrelabQuestion11',
  components: {
    ChemicalLatex,
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {
          text: 'Use only spectroscopy to analyze a new unknown that contains only the same possible compounds from the fundamental skills experiments.',
          value: 'specOnly',
        },
        {
          text: 'Use only TLC or melting range data to analyze a new unknown that contains only the same possible compounds from the fundamental skills experiments.',
          value: 'tlcOrMpOnly',
        },
        {
          text: 'Determine the optimal TLC eluting solvent for a new set of unknown compounds',
          value: 'tlcOnly',
        },
        {
          text: 'Use evidence from three different techniques to conclusively determine the identity of each compound present in a set of unknown samples that may contain the potential compounds from the fundamental skills experiments and/or three new compounds.',
          value: 'allInfo',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
